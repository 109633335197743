
import { Component, Vue } from 'vue-property-decorator'
import Api from '@/util/api'
import $store from '@/store'

@Component({
  components: {
  }
})
export default class AddLogName extends Vue {
  name = ''

  get recentLogs () {
    return $store.state.newlog.recentLogs.filter((item: string) => item.toLowerCase().includes(this.name.toLowerCase()))
  }

  save () {
    // console.log('save')
    if (this.$store.state.edit.mode === 'new') {
      Api.get('log/new', {
        params: {
          tkn: this.$store.state.tkn,
          gid: this.$store.state.newlog.group.Id,
          n: this.name
        }
      }).then(response => {
        console.log(response.data)
        if (response.data.err !== undefined) {
          console.error(response.data.err)
          return
        }
        this.$store.state.logs = response.data.reverse()
      }).catch(function (error) {
        console.log(error)
      })
      this.$router.push('/')
    } else {
      this.backToEditLog()
    }
  }

  backToEditLog () {
    this.$store.state.edit.logName = this.name
    this.$router.back()
    this.$router.back()
    // this.$router.push('/logedit')
  }

  selectRecentLog (recentLog: string) {
    this.name = recentLog
    if (this.$store.state.edit.mode === 'new') {
      this.save()
    } else {
      this.backToEditLog()
    }
  }
}
