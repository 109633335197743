import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import GroupsList from '@/components/GroupsList.vue'
import AddLogName from '@/components/AddLogName.vue'
import LogEdit from '@/components/LogEdit.vue'
import GroupEdit from '@/components/GroupEdit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/groups',
    name: 'Groups',
    component: GroupsList
  },
  {
    path: '/addnote',
    name: 'AddNote',
    component: AddLogName
  },
  {
    path: '/logedit',
    name: 'LogEdit',
    component: LogEdit
  },
  {
    path: '/groupedit',
    name: 'GroupEdit',
    component: GroupEdit
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
