export default {
  pad (val: number) {
    const valString = val.toString() + ''
    if (valString.length < 2) {
      return '0' + valString
    } else {
      return valString
    }
  }
}
