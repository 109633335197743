import $store from '@/store/index'
import Api from '@/util/api'

export default {

  newLog (name: string, groupId: string) {
    const params = {
      params: {
        tkn: $store.state.tkn,
        gid: groupId,
        n: name
      }
    }
    console.log(params)
    Api.get('log/new', params).then(response => {
      console.log(response.data)
      if (response.data.err !== undefined) {
        console.error(response.data.err)
        return
      }
      $store.state.logs = response.data.reverse()
    }).catch(function (error) {
      console.log(error)
    })
  },
  editLog (ts: Date, name: string) {
    const params = {
      params: {
        tkn: $store.state.tkn,
        lid: $store.state.editLog.Id,
        gid: $store.state.newlog.group.Id,
        ts: ts.getTime() / 1000,
        name: name
      }
    }
    console.log('edit log', params)
    Api.get('log/edit', params).then(response => {
      console.log(response.data)
      if (response.data.err !== undefined) {
        console.error(response.data.err)
        return
      }
      $store.state.logs = response.data.reverse()
    }).catch(function (error) {
      console.log(error)
    })
  },
  getRecentLogs () {
    $store.state.newlog.recentLogs = []
    const params = {
      params: {
        tkn: $store.state.tkn,
        gid: $store.state.newlog.group.Id
      }
    }
    console.log('get recent logs', params)
    Api.get('log/recent', params).then(response => {
      console.log(response.data)
      if (response.data !== null) {
        if (response.data.err !== undefined) {
          console.error(response.data.err)
          return
        }
        $store.state.newlog.recentLogs = response.data
      }
    }).catch(function (error) {
      console.log(error)
    })
  },
  updateLogs (c: number) {
    $store.state.newlog.recentLogs = []
    const params = {
      params: {
        tkn: $store.state.tkn,
        c: c
      }
    }
    // console.log('get recent logs', params)
    Api.get('log/list', params).then(response => {
      console.log(response.data)
      if (response.data !== null) {
        if (response.data.err !== undefined) {
          console.error(response.data.err)
          return
        }
        $store.state.logs = response.data.reverse()
      }
    }).catch(function (error) {
      console.log(error)
    })
  }
}
