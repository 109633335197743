
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Log } from '@/util/types'
import common from '@/services/common'
import logs from '@/services/logs'

@Component({
  components: {
  }
})
export default class LogsList extends Vue {
  logsList: Log[] = []
  logsCount = 10

  @Watch('$store.state.logs')
  logsChanged (logs: Log[]) {
    // console.log('logs:', logs)
    if (logs.length > 0) {
      this.logsList = logs.slice(0, logs.length - 1)
    }
  }

  more () {
    this.logsCount += 10
    logs.updateLogs(this.logsCount)
  }

  parseTime (utctime: string) {
    const date = new Date(utctime)
    return common.pad(date.getHours()) + ':' + common.pad(date.getMinutes())
  }

  toHHMMSS (dur: number) {
    const hours = Math.floor(dur / 3600)
    const minutes = Math.floor((dur - (hours * 3600)) / 60)
    const seconds = dur - (hours * 3600) - (minutes * 60)

    let timeStr = ''
    if (hours > 0) {
      timeStr += hours.toString() + 'h '
    }
    if (minutes > 0) {
      if (hours > 0 && minutes < 10) {
        timeStr += '0'
      }
      timeStr += minutes.toString() + 'm '
    }
    if (hours === 0 && minutes < 5 && seconds > 0) {
      if (minutes > 0 && seconds < 10) {
        timeStr += '0'
      }
      timeStr += seconds.toString() + 's'
    }
    return timeStr
  }

  restartLog (name: string, groupId: string) {
    logs.newLog(name, groupId)
  }
}
