import Vue from 'vue'
import Vuex from 'vuex'
import { Group, Log } from '@/util/types'

Vue.use(Vuex)

const tok1 = window.location.hash.substr(2)
if (tok1.length > 20) {
  localStorage.setItem('tkn', tok1)
  window.location.href = '/'
}
// console.log('token', tok1)
// console.log('token hash', window.location.hash)

export default new Vuex.Store({
  state: {
    tkn: localStorage.getItem('tkn'),
    logs: [],
    groups: [],
    newlog: {
      group: {} as Group,
      recentLogs: []
    },
    editLog: {} as Log,
    editGroup: {} as Group,
    edit: {
      mode: 'new', // new or edit
      logName: '',
      groupName: '',
      groupColor: ''
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
