
import { Component, Vue } from 'vue-property-decorator'
import groups from '@/services/groups'

@Component({
  components: {
  }
})
export default class GroupEdit extends Vue {
  save () {
    console.log('save edit group')
    groups.editGroup()
    this.$router.back()
  }

  created () {
    if (this.$store.state.edit.groupColor === '') {
      this.randomColor()
    }
  }

  randomColor () {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    this.$store.state.edit.groupColor = color
  }
}
