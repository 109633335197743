
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class NewLogButton extends Vue {
  showGroups () {
    // console.log('groups')
    this.$store.state.edit.mode = 'new'
    this.$router.push('/groups')
  }
}
