import { render, staticRenderFns } from "./AddLogName.vue?vue&type=template&id=61f4119c&scoped=true&"
import script from "./AddLogName.vue?vue&type=script&lang=ts&"
export * from "./AddLogName.vue?vue&type=script&lang=ts&"
import style0 from "./AddLogName.vue?vue&type=style&index=0&id=61f4119c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f4119c",
  null
  
)

export default component.exports