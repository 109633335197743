import $store from '@/store'
import Api from '@/util/api'

export default {
  editGroup () {
    // $store.state.newlog.recentLogs = []
    const params = {
      params: {
        tkn: $store.state.tkn,
        gid: $store.state.editGroup.Id,
        name: $store.state.edit.groupName,
        color: $store.state.edit.groupColor
      }
    }
    console.log('edit group', params)
    Api.get('group/edit', params).then(response => {
      console.log(response.data)
      if (response.data !== null) {
        if (response.data.err !== undefined) {
          console.error(response.data.err)
          return
        }
        $store.state.groups = response.data
      }
    }).catch(function (error) {
      console.log(error)
    })
  }
}
