
import { Component, Vue } from 'vue-property-decorator'
import logs from '@/services/logs'
import { Log } from '@/util/types'
import common from '@/services/common'

@Component({
  components: {}
})
export default class LogEdit extends Vue {
  selectedDate = this.initSelDate()
  hours = this.initHours()
  minutes = this.initMinutes()

  // constructor () {
  //   super()
  //   this.selectedDate = new Date()
  //   this.hours = this.initHours()
  //   this.minutes = this.initMinutes()
  //   console.log(this.selectedDate)
  // }

  get previousStarted () {
    if (this.$store.state.logs.length > 1) {
      const started = new Date((this.$store.state.logs[this.$store.state.logs.length - 2] as Log).Ts)
      return common.pad(started.getHours()) + ':' + common.pad(started.getMinutes())
    }
    return ''
  }

  selectHours (d: Date) {
    this.selectedDate = new Date(d)
  }

  selectMinutes (minute: number) {
    // console.log(minute)
    this.selectedDate.setSeconds(0)
    this.selectedDate.setMilliseconds(0)
    this.selectedDate = new Date(this.selectedDate.setMinutes(minute))
    logs.editLog(this.selectedDate, this.$store.state.edit.logName)
    this.$router.push('/')
  }

  initMinutes (): number[] {
    const res: number[] = []
    for (let i = 0; i < 60; i += 5) {
      res.push(i)
    }
    return res
  }

  initHours (): Date[] {
    const res: Date[] = []
    let lastLog: Log
    if (this.$store.state.logs.length > 1) {
      lastLog = this.$store.state.logs[this.$store.state.logs.length - 2] as Log
      this.selectedDate = new Date((this.$store.state.logs[this.$store.state.logs.length - 1] as Log).Ts)
      const now = new Date()
      now.setHours(now.getHours() + 1)
      now.setMinutes(0)
      // eslint-disable-next-line no-unmodified-loop-condition
      for (let i = new Date(lastLog.Ts); i < now; i.setHours(i.getHours() + 1)) {
        res.push(new Date(i))
      }
    }
    return res
  }

  initSelDate (): Date {
    if (this.$store.state.logs.length > 0) {
      return new Date((this.$store.state.logs[this.$store.state.logs.length - 1] as Log).Ts)
    } else {
      return new Date()
    }
  }

  chooseGroup () {
    this.$store.state.edit.mode = 'edit'
    this.$router.push('/groups')
  }
}
