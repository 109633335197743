import axios from 'axios'

// axios.defaults.withCredentials = true

export default axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
