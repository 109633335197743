
import { Component, Vue } from 'vue-property-decorator'
import { Group } from '@/util/types'
import logs from '@/services/logs'

@Component({
  components: {
  }
})
export default class GroupsList extends Vue {
  chooseGroup (group: Group) {
    // console.log('recent logs: ', group.Name)
    console.log('mode', this.$store.state.edit.mode)
    if (this.$store.state.edit.mode === 'new') {
      this.$store.state.newlog.group = group
      logs.getRecentLogs()
      this.$router.push('/addnote')
    } else {
      this.$store.state.newlog.group = group
      // console.log('group: ', this.$store.state.newlog.group)
      logs.getRecentLogs()
      this.$router.push('/addnote')
    }
  }

  editGroup (group: Group) {
    console.log('edit group', group.Name)
    this.$store.state.editGroup = group
    this.$store.state.edit.groupName = group.Name
    this.$store.state.edit.groupColor = group.Color
    this.$router.push('/groupedit')
  }

  newGroup () {
    console.log('new group')
    this.$store.state.editGroup = {}
    this.$store.state.edit.groupName = ''
    this.$store.state.edit.groupColor = ''
    this.$router.push('/groupedit')
  }
}
