
import { Component, Vue } from 'vue-property-decorator'
import ActiveLog from '@/components/ActiveLog.vue'
import LogsList from '@/components/LogsList.vue'
import NewLogButton from '@/components/NewLogButton.vue'
import logs from '@/services/logs'
import Api from '@/util/api'

@Component({
  components: {
    NewLogButton,
    LogsList,
    ActiveLog
  }
})
export default class Home extends Vue {
  created () {
    logs.updateLogs(10)

    Api.get('group/list', {
      params: {
        tkn: this.$store.state.tkn
      }
    }).then(response => {
      console.log(response.data)
      if (response.data.err !== undefined) {
        console.error(response.data.err)
        return
      }
      this.$store.state.groups = response.data
    }).catch(function (error) {
      console.log(error)
    })
  }
}
