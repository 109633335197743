
import { Component, Vue, Watch } from 'vue-property-decorator'
import common from '@/services/common'
import { Group, Log } from '@/util/types'
import Api from '@/util/api'

@Component({
  components: {
  }
})
export default class ActiveLog extends Vue {
    activeLog: Log | null = null
    totalSeconds = 0
    h = ''
    min = ''
    sec = ''

  @Watch('$store.state.logs')
    logsChanged (logs: Log[]) {
      // console.log('logs:', logs)
      if (logs.length > 0) {
        this.activeLog = logs[logs.length - 1]
        this.totalSeconds = (new Date().getTime() - new Date(this.activeLog.Ts).getTime()) / 1000
      }
    }

  created () {
    setInterval(this.setTime, 1000)
  }

  deleteLastLog () {
    console.log('deleteLastLog')
    Api.get('log/del', {
      params: {
        tkn: this.$store.state.tkn
      }
    }).then(response => {
      console.log(response.data)
      if (response.data.err !== undefined) {
        console.error(response.data.err)
        return
      }
      this.$store.state.logs = response.data.reverse()
    }).catch(function (error) {
      console.log(error)
    })
  }

  setTime () {
    // ++this.totalSeconds
    if (!this.activeLog) {
      return
    }
    this.totalSeconds = (new Date().getTime() - new Date(this.activeLog.Ts).getTime()) / 1000
    // this.sec = common.pad(Number(this.totalSeconds.toFixed(0)) % 60)
    // this.min = common.pad(parseInt((this.totalSeconds / 60).toString()))

    const hour = Math.floor(this.totalSeconds / 3600)
    const minute = Math.floor((this.totalSeconds - hour * 3600) / 60)
    const seconds = Math.trunc(this.totalSeconds - (hour * 3600 + minute * 60))
    let hStr = hour.toString()
    let minStr = minute.toString()
    let secStr = seconds.toString()
    if (hour < 10) {
      hStr = '0' + hour
    }
    if (minute < 10) {
      minStr = '0' + minute
    }
    if (seconds < 10) {
      secStr = '0' + seconds
    }
    this.h = hStr
    this.min = minStr
    this.sec = secStr
  }

  parseTime (utctime: string) {
    const date = new Date(utctime)
    return common.pad(date.getHours()) + ':' + common.pad(date.getMinutes())
  }

  editLog () {
    this.$store.state.editLog = this.activeLog
    this.initGroup()
    this.$store.state.edit.logName = (this.$store.state.logs[this.$store.state.logs.length - 1] as Log).Name
    this.$router.push('/logedit')
  }

  initGroup () {
    const group1 = this.$store.state.groups.find((group: Group) => {
      if (group.Id === (this.$store.state.logs[this.$store.state.logs.length - 1] as Log).Gid) {
        return group
      }
    })
    this.$store.state.newlog.group = group1
    console.log('init group', this.$store.state.newlog.group)
  }
}
